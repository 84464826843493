<template>
  <div>
    <validation-provider
      #default="{ errors }"
      name="report"
      :rules="requiredField ? 'required' : ''"
    >
      <b-form-group
        :label="title"
        label-for="report"
      >
        <v-select
          id="report_input"
          v-model="report"
          :class="errors.length > 0 ? 'error-select' : ''"
          :filter="fuseSearch"
          label="name"
          :options="dataOptions"
          :placeholder="title"
          :disabled="readonly"
          :clearable="false"
          @open="onCreate()"
        >
          <template #option="{ name }">
            <span>{{ name }} </span>
          </template>

          <template #selected-option="{ name }">
            <span>{{ name }} </span>
          </template>

          <template #no-options>
            Não há opções de {{ title }}.
          </template>
        </v-select>
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
  </div>
</template>
<script>
import {
  BFormGroup,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import {
  required,
} from '@validations'

import vSelect from 'vue-select'
import Fuse from 'fuse.js'
import syslic from '@/syslic'

export default {
  components: {
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  model: {
    prop: 'reportValue',
    event: 'reportChange',
  },
  props: {
    reportValue: {
      type: [Object, String],
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: {
      type: String,
      required: false,
      default: 'Relatório',
    },
  },
  data() {
    const dataOptions = []

    return {
      dataOptions,
      required,
    }
  },
  computed: {
    report: {
      get() {
        return this.reportValue
      },
      set(value) {
        this.$emit('reportChange', value)
      },
    },
  },
  created() {
    this.onCreate()
  },
  methods: {
    onCreate() {
      syslic
        .report
        .fetchReports()
        .then(response => {
          this.dataOptions = response.data.results
          this.dataOptions.forEach(item => {
            if (item.default) {
              this.report = item
            }
          })

          this.$emit('ready', true)
        })
        .catch(() => {
          this.$emit('ready', true)
        })
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name'],
        shouldSort: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
