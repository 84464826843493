<template>
  <div>
    <div v-show="isLoading">
      <spin-loader />
    </div>
    <div v-show="!isLoading">
      <b-row>
        <b-col>
          <b-card
            no-body
            class="mb-0"
          >
            <div class="pr-2 pl-2">
              <b-row class="d-flex justify-content-between">
                <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mt-1 mb-1"
                >
                  <div class="ml-1 w-100">
                    <report-select
                      id="reportInput"
                      v-model="report"
                      @ready="syncLoad.ready('report')"
                    />
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row
        class="mt-2"
      >
        <b-col>
          <div v-if="(loadReport || (report.superset_id == ''))">
            <spin-loader />
          </div>
          <div v-else>
            <superset-dashboard
              :dashboard="report.superset_id"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'

import SyncLoad from '@/components/utils/syncLoad'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import ReportSelect from '@/components/report/ReportSelect.vue'
import SupersetDashboard from '@/components/report/SupersetDashboard.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    SpinLoader,
    ReportSelect,
    SupersetDashboard,
  },
  data() {
    const report = {
      id: '',
      name: '',
      superset_id: '',
      default: false,
    }

    const isLoading = true
    const loadReport = false
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('report')

    syncLoad.onReady(() => {
      this.isLoading = false
    })

    return {
      report,
      syncLoad,
      isLoading,
      loadReport,
    }
  },
  watch: {
    'report.superset_id': function updateDashboard() {
      this.loadReport = true
      setTimeout(() => {
        this.loadReport = false
      }, 1000)
    },
  },
}
</script>

<style lang="scss">
</style>
