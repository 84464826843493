<template>
  <div>
    <b-row>
      <b-col>
        <div id="idSysLicSupersetDashboard" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'

import { embedDashboard } from '@superset-ui/embedded-sdk'
import syslic from '@/syslic'

export default {
  components: {
    BRow,
    BCol,
  },
  props: {
    dashboard: {
      type: [String],
      required: true,
    },
  },
  data() {
    return {
      token: '',
    }
  },
  watch: {
    dashboard: function updateDahsboard(val) {
      if (val) {
        this.load(val)
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.load(this.dashboard)
    })
  },
  methods: {
    load(dashboard) {
      const userData = syslic.authentication.getProfile()

      embedDashboard({
        id: dashboard,
        supersetDomain: syslic.superset.getDomain(),
        mountPoint: document.getElementById('idSysLicSupersetDashboard'),
        fetchGuestToken: () => syslic.superset.getToken(dashboard),
        dashboardUiConfig: {
          hideTitle: true,
          filters: {
            expanded: true,
          },
          urlParams: {
            account: userData.context_account,
          },
        },
      })
    },
  },
}
</script>

<style lang="scss">
#idSysLicSupersetDashboard {
  height: calc(100vh - 14rem);
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 0.428rem;
  background-clip: border-box;
}

#idSysLicSupersetDashboard > iframe {
  border: 0px;
  width: 100%;
  height: 100%;
  border-radius: 0.428rem;
}
</style>
